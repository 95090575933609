import { FunctionComponent, useMemo } from 'react';
import CleanCard from './CleanCard';
import { GraphAnalyticsCardProps } from '../../AnalyticsCard';
import PieChart from '../../../PieChart';

const CustomerFeedback: FunctionComponent<GraphAnalyticsCardProps<Record<string, { month: string; value: number }[]>>> = ({
    data,
    title,
    subtitle,
}) => {
    const monthsTotalAvgs = useMemo((): { month: string; value: number }[] => {
        if (!data) return [];
        const avgs = Object.entries(data).reduce(
            (acc, [_, value]) => {
                value.forEach((v) => {
                    if (!acc[v.month]) acc[v.month] = 0;
                    acc[v.month] += v.value;
                });
                return acc;
            },
            {} as Record<string, number>,
        );

        return Object.entries(avgs)
            .slice()
            .reverse()
            .map(([month, value]) => ({ month, value: value / Object.keys(data).length }));
    }, [data]);

    const pieNumber: number | undefined = monthsTotalAvgs[monthsTotalAvgs.length - 1]?.value;
    const barColor = useMemo(() => {
        if (pieNumber < 95) return '#c3ad94';
        return '#AFD648';
    }, [pieNumber]);
    return (
        <CleanCard
            title={title}
            subtitle={subtitle}
            reachedBonus={pieNumber >= 95}
        >
            <div className="flex gap-8 justify-evenly items-center h-full">
                <div className="flex flex-col gap-5 justify-center items-center h-full">
                    <PieChart
                        number={Math.round(Number(pieNumber))}
                        barColor={barColor}
                        indicatorPercentage={95}
                    />
                    <span> </span>
                </div>
                <table className="table-auto">
                    <thead className=" bg-beige-100">
                        <tr>
                            <th aria-label="empty field">&nbsp;</th>
                            {monthsTotalAvgs.map((m) => (
                                <th
                                    key={m.month}
                                    className="py-2 px-4 text-xl"
                                >
                                    <span>{m.month}.</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            Object.entries(data)
                                .slice()
                                .reverse()
                                .map(([key, value], index) => (
                                    <tr
                                        key={`statistics_${key}`}
                                        className={index % 2 === 1 ? 'bg-beige-50' : ''}
                                    >
                                        <td className="pr-4 text-xl">
                                            <span>{key}</span>
                                        </td>
                                        {value.map((v) => (
                                            <td
                                                key={v.month}
                                                className="text-center text-xl"
                                            >
                                                {v.value}%
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                    </tbody>
                    <tfoot className="border-t">
                        <tr>
                            <td className="font-medium pt-2 text-xl">Gesamt</td>
                            {monthsTotalAvgs.map((m) => (
                                <td
                                    key={m.month}
                                    className="text-center text-xl font-bold"
                                >
                                    <span>{Math.round(m.value)}%</span>
                                </td>
                            ))}
                        </tr>
                    </tfoot>
                </table>
            </div>
        </CleanCard>
    );
};

export default CustomerFeedback;
