import { FunctionComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import CleanCard from './CleanCard';
import { GraphAnalyticsCardProps } from '../../AnalyticsCard';

const ProductivityLastThreeMonths: FunctionComponent<GraphAnalyticsCardProps> = ({ data, labels }) => {
    const chartData: ChartData<'bar'> = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: data.map((value) => {
                    if (value < 0.94) return '#e1d6ca';
                    return '#d7eaa4';
                }),
                borderColor: 'rgba(255, 99, 132, 0.2)',
                borderRadius: 10,
            },
        ],
    };
    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                offset: -20,
                font: {
                    weight: 'bold',
                    size: 16,
                },
                color: 'black',
                formatter(value: number) {
                    if (!value) return null;
                    return `${value.toFixed(2)}`;
                },
            },
            legend: {
                display: false,
            },
            annotation: {
                annotations: {
                    line1: {
                        type: 'line',
                        scaleID: 'y',
                        value: 0.94,
                        borderColor: 'black',
                        borderWidth: 2,
                        borderDash: [5, 5],
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 10,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <CleanCard
            title="Produktivität der letzten Monate"
            reachedBonus={data[0] > 0.94}
        >
            <div className=" flex justify-stretch gap-4">
                <div className="flex flex-col justify-start mt-[6%]">
                    <span>Zielwert</span>
                    <span className="flex px-1 py-0.5 justify-center rounded border-2 border-dashed font-medium">0,94</span>
                </div>
                <div className="flex flex-col justify-start flex-1 h-80">
                    <Bar
                        data={chartData}
                        options={chartOptions}
                    />
                </div>
            </div>
        </CleanCard>
    );
};

export default ProductivityLastThreeMonths;
