import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { StoreAnalyticsDataRequest, StoredAnalytics, StoredAnalyticsConfig } from '@mywellness-gmbh/backend-types';
import { endOfMonth, endOfWeek, endOfYear, format, startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks, subYears } from 'date-fns';
import Modal from './Modal';
import { getStoredAnalyticsConfig, loadAnalyticsDataForSpecificDate, storeAnalyticsData } from '../../api/storedAnalytics';
import { useUser } from '../../context/UserProvider';

type EditValuesModalProps = {
    closeModal: () => void;
    configIdentifiers: {
        identifier: string;
        humanReadable?: string;
    }[];
    name: string;
};

type CalendarWeek = {
    kw: string;
    kwNumber: number;
    yearNumber: number;
    date: Date;
};

type Month = {
    month: string;
    monthNumber: number;
    yearNumber: number;
    date: Date;
};

const getLastCalendarWeeks = (weeks: number): CalendarWeek[] => {
    const lastKws: CalendarWeek[] = [];
    const currentDate = new Date();
    const compareKw = subWeeks(currentDate, 1); // use last kw as compare value
    for (let i = 0; i < weeks; i += 1) {
        const newDate = subWeeks(compareKw, i);
        lastKws.push({
            kw: format(newDate, 'RRRR/II', {
                weekStartsOn: 1,
            }),
            kwNumber: parseInt(
                format(newDate, 'II', {
                    weekStartsOn: 1,
                }),
                10,
            ),
            yearNumber: parseInt(
                format(newDate, 'RRRR', {
                    weekStartsOn: 1,
                }),
                10,
            ),
            date: newDate,
        });
    }
    return lastKws;
};

const getLastMonths = (months: number): Month[] => {
    const lastMonths: Month[] = [];
    const currentDate = new Date();
    const compareMonth = subMonths(currentDate, 1);
    for (let i = 0; i < months; i += 1) {
        const newDate = subMonths(compareMonth, i);
        lastMonths.push({
            month: format(newDate, 'RRRR/MM', {
                weekStartsOn: 1,
            }),
            monthNumber: parseInt(
                format(newDate, 'MM', {
                    weekStartsOn: 1,
                }),
                10,
            ),
            yearNumber: parseInt(
                format(newDate, 'RRRR', {
                    weekStartsOn: 1,
                }),
                10,
            ),
            date: newDate,
        });
    }
    return lastMonths;
};

const getLastYears = (years: number): number[] => {
    const lastYears: number[] = [];
    const currentDate = new Date();
    const compareYear = subYears(currentDate, 1);
    for (let i = 0; i < years; i += 1) {
        const newDate = subYears(compareYear, i);
        lastYears.push(parseInt(format(newDate, 'RRRR'), 10));
    }
    return lastYears;
};

const getYearNumber = (storeType: string, currentKw: CalendarWeek, currentMonth: Month, currentYear: number): number => {
    if (storeType === 'week') return currentKw.yearNumber;
    if (storeType === 'month') return currentMonth.yearNumber;
    if (storeType === 'year') return currentYear;
    throw new Error('Invalid storeType (neither week, month nor year)');
};

type StoredAnalyticsConfigTMP = Omit<StoredAnalytics, 'data' | 'configs'> & {
    configs: (StoredAnalyticsConfig & { value: string })[];
    humanReadable: string;
};

const EditValuesModal: FunctionComponent<EditValuesModalProps> = ({ closeModal, configIdentifiers, name }) => {
    const [configs, setConfigs] = useState<StoredAnalyticsConfigTMP[]>([]);
    const lastKws = getLastCalendarWeeks(30);
    const lastMonths = getLastMonths(30);
    const lastYears = getLastYears(30);
    const [currentKw, setCurrentKw] = useState<CalendarWeek>(lastKws[0]);
    const [currentMonth, setCurrentMonth] = useState<Month>(lastMonths[0]);
    const [currentYear, setCurrentYear] = useState<number>(subYears(new Date(), 1).getFullYear());
    const { user } = useUser();
    const formRef = useRef<HTMLFormElement>(null);

    const submitSaveForm = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (!configs) return;
        if (!user) return;
        if (formRef.current?.reportValidity()) {
            configs.forEach((config) => {
                const request: StoreAnalyticsDataRequest = {
                    identifier: config.identifier,
                    kw: config.storeType === 'week' ? currentKw.kwNumber : undefined,
                    month: config.storeType === 'month' ? currentMonth.monthNumber : undefined,
                    year: getYearNumber(config.storeType, currentKw, currentMonth, currentYear),
                    values: config.configs.reduce((acc, configItem) => {
                        if (!configItem.value) return acc;
                        return {
                            ...acc,
                            [configItem.identifier]: parseFloat(configItem.value),
                        };
                    }, {}),
                };
                storeAnalyticsData(user?.token, request).then(() => {
                    closeModal();
                });
            });
        }
    };

    const fillInputs = async (config: StoredAnalyticsConfigTMP): Promise<boolean> => {
        if (!user) return false;
        if (configs.length === 0) return false;
        if (!config.storeType) return false;
        let from;
        let to;
        if (config.storeType === 'week') {
            from = startOfWeek(currentKw.date, { weekStartsOn: 1 });
            to = endOfWeek(currentKw.date, { weekStartsOn: 1 });
        }
        if (config.storeType === 'month') {
            from = startOfMonth(currentMonth.date);
            to = endOfMonth(currentMonth.date);
        }
        if (config.storeType === 'year') {
            from = startOfYear(currentYear);
            to = endOfYear(currentYear);
        }
        if (!from || !to) return false;
        const data = await loadAnalyticsDataForSpecificDate(user?.token, [config.identifier], from, to);

        setConfigs((prevConfigs) => {
            if (!prevConfigs) return prevConfigs;
            return prevConfigs.map((prevConfig) => {
                const newData = data.filter((d) => d.analyticsId === prevConfig.id);
                if (!newData.length) return prevConfig;
                return {
                    ...prevConfig,
                    configs: prevConfig.configs.map((prevConfigItem) => {
                        const value = newData.find((d) => d.identifier === prevConfigItem.identifier)?.value;
                        return {
                            ...prevConfigItem,
                            value: value ? value.toString() : '',
                        };
                    }),
                };
            });
        });
        return true;
    };

    useEffect(() => {
        if (!user) return;
        getStoredAnalyticsConfig(
            user?.token,
            configIdentifiers.map((configIdentifier) => configIdentifier.identifier),
        ).then((loadedConfigs) => {
            const newConfigs: StoredAnalyticsConfigTMP[] = loadedConfigs.map((loadedConfig) => {
                const humanReadable =
                    configIdentifiers.find((configIdentifier) => configIdentifier.identifier === loadedConfig.result.identifier)
                        ?.humanReadable ?? '';
                return {
                    ...loadedConfig.result,
                    humanReadable,
                    configs: loadedConfig.result.configs.map((configItem) => ({
                        ...configItem,
                        value: '',
                    })),
                };
            });
            setConfigs(newConfigs);
            newConfigs.forEach((config) => {
                fillInputs(config);
            });
        });
    }, [configIdentifiers, user]);

    useEffect(() => {
        // load corresponding data when changing kw, month or year
        fillInputs(configs[0]);
    }, [currentKw, currentMonth, currentYear]);

    return (
        <Modal onClose={closeModal}>
            <form
                onSubmit={submitSaveForm}
                ref={formRef}
            >
                <h2 className="font-bold text-2xl uppercase tracking-wider">Eigene Werte</h2>
                <div className="flex flex-col gap-6 mt-6">
                    {
                        configs.map((config) => {
                            if (config.storeType === 'week')
                                return (
                                    <label
                                        htmlFor="kw"
                                        className="flex items-center gap-2 text-xl"
                                    >
                                        <span className="min-w-[7rem]">KW</span>
                                        <select
                                            id="kw"
                                            className="text-xl font-bold p-3 bg-beige-100/50 h-12 rounded"
                                            value={currentKw.kw}
                                            onChange={(e): void => {
                                                const selectedKw = lastKws.find((kw) => kw.kw === e.target.value);
                                                if (selectedKw) {
                                                    setCurrentKw(selectedKw);
                                                }
                                            }}
                                        >
                                            {lastKws.map((kw) => (
                                                <option
                                                    key={kw.kw}
                                                    value={kw.kw}
                                                >
                                                    {kw.kw}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                );

                            if (config.storeType === 'month')
                                return (
                                    <label
                                        htmlFor="month"
                                        className="flex items-center gap-2 text-xl"
                                    >
                                        <span className="min-w-[7rem]">Monat</span>
                                        <select
                                            id="month"
                                            className="text-xl font-bold p-3 bg-beige-100/50 h-12 rounded"
                                            value={currentMonth.month}
                                            onChange={(e): void => {
                                                const selectedMonth = lastMonths.find((month) => month.month === e.target.value);
                                                if (selectedMonth) {
                                                    setCurrentMonth(selectedMonth);
                                                }
                                            }}
                                        >
                                            {lastMonths.map((month) => (
                                                <option
                                                    key={month.month}
                                                    value={month.month}
                                                >
                                                    {month.month}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                );

                            if (config.storeType === 'year')
                                return (
                                    <label
                                        htmlFor="year"
                                        className="flex item-center gap-2 text-xl"
                                    >
                                        <span className="min-w-[7rem]">Jahr</span>
                                        <select
                                            id="year"
                                            className="text-xl font-bold p-3 bg-beige-100/50 h-12 rounded"
                                            value={currentYear}
                                            onChange={(e): void => {
                                                const selectedYear = lastYears.find((year) => year === parseInt(e.target.value, 10));
                                                if (selectedYear) {
                                                    setCurrentYear(selectedYear);
                                                }
                                            }}
                                        >
                                            {lastYears.map((year) => (
                                                <option
                                                    key={year}
                                                    value={year}
                                                >
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                );

                            throw new Error('Invalid storeType (neither week, month nor year)');
                        })[0]
                    }
                    <div>
                        <h3 className="font-bold text-xl uppercase tracking-wider mb-4">{name}</h3>
                        <div className="flex flex-col gap-2">
                            {configs
                                .sort((a, b) => a.id - b.id)
                                .map((config) =>
                                    config.configs.map((configItem) => (
                                        <label
                                            htmlFor={configItem.identifier}
                                            className="flex items-center w-full gap-2 text-xl"
                                            key={configItem.identifier}
                                        >
                                            <span className="min-w-[7rem]">
                                                {config.humanReadable !== ''
                                                    ? `${configItem.name} - ${config.humanReadable}`
                                                    : configItem.name}
                                            </span>
                                            <input
                                                id={configItem.identifier}
                                                type="number"
                                                min="0"
                                                className="w-full p-4 font-bold text-xl bg-beige-100/50 h-12 rounded placeholder:text-dark-chocolate-100/50"
                                                placeholder={configItem.unit}
                                                value={configItem.value}
                                                step="0.01"
                                                required
                                                onChange={(e): void =>
                                                    setConfigs((prevConfigs) => {
                                                        if (!prevConfigs) return prevConfigs;
                                                        const editingConfig = prevConfigs.find(
                                                            (prevConfig) => prevConfig.identifier === config.identifier,
                                                        );
                                                        if (!editingConfig) return prevConfigs;

                                                        return [
                                                            ...prevConfigs.filter(
                                                                (prevConfig) => prevConfig.identifier !== config.identifier,
                                                            ),
                                                            {
                                                                ...editingConfig,
                                                                configs: editingConfig.configs.map((prevConfigItem) => {
                                                                    if (prevConfigItem.identifier === configItem.identifier) {
                                                                        return {
                                                                            ...prevConfigItem,
                                                                            value: e.target.value,
                                                                        };
                                                                    }
                                                                    return prevConfigItem;
                                                                }),
                                                            },
                                                        ];
                                                    })
                                                }
                                            />
                                        </label>
                                    )),
                                )}
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <button
                        type="submit"
                        className="bg-avocado-75 px-6 py-4 mt-8 mb-4 ml-auto rounded-full text-white tracking-wider text-xl uppercase"
                    >
                        Speichern
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default EditValuesModal;
