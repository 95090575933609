import { FunctionComponent, useMemo } from 'react';
import PieChart from '../../PieChart';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const OutletFeedbackComparisonCard: FunctionComponent<GraphAnalyticsCardProps<Record<string, { month: string; value: number }[]>>> = ({
    data,
    title,
    subtitle,
}) => {
    const monthsTotalAvgs = useMemo((): { month: string; value: number }[] => {
        if (!data) return [];
        const avgs = Object.entries(data).reduce(
            (acc, [_, value]) => {
                value.forEach((v) => {
                    if (!acc[v.month]) acc[v.month] = 0;
                    acc[v.month] += v.value;
                });
                return acc;
            },
            {} as Record<string, number>,
        );

        return Object.entries(avgs).map(([month, value]) => ({ month, value: value / Object.keys(data).length }));
    }, [data]);

    const pieNumber: number | undefined = monthsTotalAvgs[monthsTotalAvgs.length - 1]?.value;
    const barColor = useMemo(() => {
        if (pieNumber < 50) return '#e6765a';
        if (pieNumber < 95) return '#edcd77';
        return '#90AF76';
    }, [pieNumber]);
    return (
        <AnalyticsCard
            title={title || 'Feedback'}
            subtitle={subtitle}
            live
        >
            <div className="flex gap-8 justify-center items-center h-full">
                <PieChart
                    number={Math.round(Number(pieNumber))}
                    barColor={barColor}
                />
                <table className="table-auto">
                    <thead className="border-b">
                        <tr>
                            <th aria-label="empty field">&nbsp;</th>
                            {monthsTotalAvgs.map((m) => (
                                <th
                                    key={m.month}
                                    className="py-1 px-2 *:font-medium"
                                >
                                    <span>{m.month}.</span>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data &&
                            Object.entries(data).map(([key, value]) => (
                                <tr key={`statistics ${key}`}>
                                    <td className="pr-4 *:font-medium">
                                        <span>{key}</span>
                                    </td>
                                    {value.map((v) => (
                                        <td
                                            key={v.month}
                                            className="text-center"
                                        >
                                            {v.value}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                    <tfoot className="border-t">
                        <tr>
                            <td className="font-medium pt-2">Gesamt</td>
                            {monthsTotalAvgs.map((m) => (
                                <td
                                    key={m.month}
                                    className="text-center *:font-medium"
                                >
                                    <span>{Math.round(m.value)}</span>
                                </td>
                            ))}
                        </tr>
                    </tfoot>
                </table>
            </div>
        </AnalyticsCard>
    );
};

export default OutletFeedbackComparisonCard;
