import { FunctionComponent } from 'react';
import CleanCard from './CleanCard';
import PieChart from '../../../PieChart';
import { GraphAnalyticsCardProps } from '../../AnalyticsCard';

const LastAuditsPies: FunctionComponent<GraphAnalyticsCardProps<{ month: string; value: number }[]>> = ({ data }) => {
    return (
        <CleanCard
            title="Letzten 3 Audits"
            reachedBonus={data[2]?.value > 95}
        >
            <div className="flex flex-col gap-4 align-center justify-center h-full w-full">
                <div className="grid grid-cols-3 gap-4">
                    <div className="flex flex-col gap-5 justify-center items-center h-full">
                        <PieChart
                            number={data[2]?.value || 0}
                            barColor={data[2]?.value < 95 ? '#c3ad94' : '#AFD648'}
                            indicatorPercentage={95}
                        />
                        <h1> {data[2]?.month}</h1>
                    </div>
                    <div className="flex gap-8 col-span-2 justify-center items-center h-full border-l-2 opacity-50">
                        <div className="flex flex-col gap-5 justify-center items-center h-full">
                            <PieChart
                                number={data[1]?.value || 0}
                                barColor={data[2]?.value < 95 ? '#c3ad94' : '#AFD648'}
                                indicatorPercentage={95}
                            />
                            <h1> {data[1]?.month}</h1>
                        </div>
                        <div className="flex flex-col gap-5 justify-center items-center h-full">
                            <PieChart
                                number={data[0]?.value || 0}
                                barColor={data[2]?.value < 95 ? '#c3ad94' : '#AFD648'}
                                indicatorPercentage={95}
                            />
                            <h1> {data[0]?.month}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </CleanCard>
    );
};

export default LastAuditsPies;
