import { FunctionComponent } from 'react';
import { GraphAnalyticsCardProps } from '../../AnalyticsCard';
import CleanCard from './CleanCard';
import formCheck from '../../../../icons/icon__form-check.svg';
import formCross from '../../../../icons/icon__form-cross.svg';

const ResignationInProbationary: FunctionComponent<GraphAnalyticsCardProps<{ month: string; value: number }[]>> = ({ data }) => {
    return (
        <CleanCard
            title="Kündigungen in der Probezeit"
            reachedBonus={data[2]?.value === 0}
        >
            <div className="flex flex-col gap-4 align-center justify-center h-full">
                <div className="flex justify-evenly ">
                    <div className="flex flex-col gap-8 justify-center items-center h-full">
                        <img
                            className="h-40"
                            src={data[2]?.value !== 0 ? formCross : formCheck}
                            alt=""
                        />
                        <h5>{data[2]?.month}</h5>
                    </div>
                    <div className="w-[2px] bg-black-50" />
                    <div className="flex gap-16 my-auto justify-center items-center h-full opacity-50">
                        <div className="flex flex-col gap-8 justify-center items-center h-full">
                            <img
                                className="h-32"
                                src={data[1]?.value !== 0 ? formCross : formCheck}
                                alt=""
                            />
                            <h5>{data[1]?.month}</h5>
                        </div>
                        <div className="flex flex-col gap-8 justify-center items-center h-full">
                            <img
                                className="h-32"
                                src={data[0]?.value !== 0 ? formCross : formCheck}
                                alt=""
                            />
                            <h5>{data[0]?.month}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </CleanCard>
    );
};

export default ResignationInProbationary;
