import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AnalyticsCard, { GraphAnalyticsCardProps } from '../AnalyticsCard';

const ProductivityLastMonths: React.FC<GraphAnalyticsCardProps> = ({ data, labels }) => {
    const chartData: ChartData<'bar'> = {
        labels,
        datasets: [
            {
                data,
                backgroundColor: data.map((value) => {
                    if (value < 0.5) return '#e6765a';
                    if (value < 0.77) return '#edcd77';
                    return '#90AF76';
                }),
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

    const chartOptions: ChartOptions<'bar'> = {
        plugins: {
            tooltip: { enabled: false },
            datalabels: {
                anchor: 'end',
                align: 'end',
                offset: -4,
                formatter(value: number) {
                    if (!value) return null;
                    return `${value.toFixed(2)}`;
                },
            },
            legend: {
                display: false,
            },
        },
        layout: {
            padding: {
                top: 35,
            },
        },
        scales: {
            y: {
                display: false,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <AnalyticsCard
            title="Produktivität der letzten Monate"
            live
        >
            <Bar
                data={chartData}
                options={chartOptions}
            />
        </AnalyticsCard>
    );
};

export default ProductivityLastMonths;
