import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useViewOutletStatsData } from '../context/ViewOutletStatsProvider';
import { getLastThreeMonths } from '../util';
import CustomerFeedback from '../components/analytics/cards/OutletViewCards/CustomerFeedback';
import LastAuditsPies from '../components/analytics/cards/OutletViewCards/LastAuditsPies';
import ProductivityLastThreeMonths from '../components/analytics/cards/OutletViewCards/ProductivityOfLastThreeMonths';
import ResignationInProbationary from '../components/analytics/cards/OutletViewCards/ResignationInProbationary';

const ViewOutletStats: FunctionComponent = () => {
    const { displayName } = useParams();
    const { data, fetchData } = useViewOutletStatsData();
    const months = getLastThreeMonths();

    useEffect(() => {
        if (displayName) {
            fetchData(displayName);
            const intervalTimer = setInterval(() => fetchData(displayName), 1000 * 60 * 5);
            return () => clearInterval(intervalTimer);
        }
        return undefined;
    }, [displayName]);

    return (
        <div className="grid grid-cols-1 lg:grid-cols-[repeat(auto-fill,_minmax(37rem,_1fr))] gap-5 px-4 md:px-10 xl:px-20 py-4 md:py-10 xl:py-20">
            <CustomerFeedback
                data={data.feedback}
                title="Kundenfeedback"
            />
            <LastAuditsPies
                data={data.audits}
                labels={months}
            />
            <ProductivityLastThreeMonths
                data={data.productivity
                    .slice()
                    .reverse()
                    .map((p) => p.value)}
                labels={months.slice().reverse()}
            />
            <ResignationInProbationary
                data={data.resignation}
                title="Kündigungen in der Probezeit"
            />
        </div>
    );
};

export default ViewOutletStats;
