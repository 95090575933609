import React, { useEffect, useRef, useState } from 'react';
import style from '../styles/components/pie_chart.module.css';

interface Title {
    title?: string;
    number: number;
    numberToDisplay?: number;
    barColor?: string;
    indicatorPercentage?: number;
}

const Chart: React.FunctionComponent<Title> = ({ title, number, numberToDisplay, barColor, indicatorPercentage }) => {
    const [counter, setCounter] = useState(0);
    const count = useRef(counter);
    count.current = counter;

    function countUp(): void {
        setCounter((currentCount) => currentCount + 1);
    }

    function countDown(): void {
        if (count.current === 0) {
            setCounter(0);
        } else if (count.current > 0) {
            setCounter((currentCount) => currentCount - 1);
        }
    }

    useEffect(() => {
        const countUpInterval = setInterval(() => {
            if (count.current < number) {
                countUp();
            } else if (count.current > number) {
                countDown();
            } else {
                clearInterval(countUpInterval);
            }
        }, 50);
        return () => clearInterval(countUpInterval);
    }, [number]);

    const replaceDecimal = (num: number): string => {
        const numString = num.toString();
        if (numString.includes('.')) {
            return numString.replace('.', ',');
        }
        return numString;
    };

    return (
        <div
            className={style.pie_chart}
            style={{
                ['--percent' as string]: counter,
                ['--ring-vg' as string]: barColor,
                ['--indicator-percentage' as string]: `${indicatorPercentage}deg`,
            }}
        >
            {indicatorPercentage && <p className={style.indicatorValue}>{indicatorPercentage}&thinsp;%</p>}
            <div className={`${style.piechart_wrapper} ${title === 'Vorwoche' || title === 'Vorjahr' ? 'saturate-0' : ''}`}>
                <div className={style.pie} />
                <p className={style.percent}>
                    <span>{(numberToDisplay && replaceDecimal(numberToDisplay)) || counter}</span>
                    {numberToDisplay ? '' : <span className={style.symbol}>%</span>}
                </p>
                {indicatorPercentage && <hr className={style.indicatorLine} />}
            </div>
            <p className={style.title}>{title}</p>
        </div>
    );
};

export default Chart;
