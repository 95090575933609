import { FunctionComponent, ReactNode, useState, useEffect } from 'react';

type CleanCardProps = {
    children: ReactNode;
    title?: string;
    subtitle?: string;
    icon?: string;
    reachedBonus?: boolean;
};

const CleanCard: FunctionComponent<CleanCardProps> = ({ children, icon, title, subtitle, reachedBonus }) => {
    const [currentWidth, setWidth] = useState(0);
    const [currentOpacityHeading, setOpacityHeading] = useState(0);
    const [currentScoreOpacity, setScoreOpacity] = useState(0);

    function addWidth(): void {
        setWidth(1);
    }
    function setOpacity(): void {
        setOpacityHeading(1);
    }
    function setScore(): void {
        setScoreOpacity(1);
    }

    useEffect(() => {
        addWidth();
        setTimeout(setOpacity, 700);
        setTimeout(setScore, 1400);
    }, []);

    return (
        <section
            className="card"
            style={{ transform: `scaleX(${currentWidth})`, backgroundColor: reachedBonus ? '#ebf5d1' : 'white' }}
        >
            <aside
                className="flex items-center gap-x-6 justify-end max-sm:mb-4 duration-500 transition-opacity"
                style={{ opacity: currentOpacityHeading }}
            />

            <header
                className="card-header"
                style={{ opacity: currentOpacityHeading }}
            >
                <div>
                    <h2 className="inline-block mb-1 font-bold text-4xl uppercase tracking-wider break-word">
                        <img
                            className="inline-block h-8 -translate-y-1"
                            src={icon}
                            alt=""
                        />
                        {title}
                    </h2>
                </div>
                {subtitle && <p className="text-center text-2xl">{subtitle}</p>}
            </header>

            <div
                className="duration-500 transition-opacity overflow-y-auto h-80"
                style={{ opacity: currentScoreOpacity }}
            >
                {children}
            </div>
        </section>
    );
};

export default CleanCard;
