import {
    GetStoredAnalyticsConfigResponse,
    GetStoredAnalyticsDataResponse,
    StoreAnalyticsDataRequest,
    StoreAnalyticsDataResponse,
    StoredAnalyticsData,
    Timeslot,
} from '@mywellness-gmbh/backend-types';
import { backendConnector, sendGetRequest, sendPostRequest } from '.';

export const getStoredAnalyticsConfig = async (token: string, identifiers: string[]): Promise<GetStoredAnalyticsConfigResponse[]> => {
    const requests = identifiers.map((singleIdentifier) =>
        sendGetRequest<GetStoredAnalyticsConfigResponse>(token, `/statistics/stored-analytics/${singleIdentifier}/config`),
    );
    return Promise.all(requests);
};

export const storeAnalyticsData = (token: string, body: StoreAnalyticsDataRequest): Promise<StoreAnalyticsDataResponse> => {
    return sendPostRequest(token, `/statistics/stored-analytics`, body);
};

export const loadAnalyticsData = async <T extends string | string[]>(
    token: string,
    identifiers: T,
    timeslot: Timeslot,
): Promise<T extends string[] ? GetStoredAnalyticsDataResponse[] : GetStoredAnalyticsDataResponse> => {
    if (Array.isArray(identifiers)) {
        const requests = identifiers.map((singleIdentifier) =>
            sendGetRequest<GetStoredAnalyticsDataResponse>(
                token,
                `/statistics/stored-analytics/?identifier=${singleIdentifier}&timeslot=${timeslot}`,
            ),
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Promise.all(requests) as any; // this is unclean, but necessary, because typescript gets confused with the generic T
    }
    return sendGetRequest<GetStoredAnalyticsDataResponse>(
        token,
        `/statistics/stored-analytics/?identifier=${identifiers}&timeslot=${timeslot}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any; // s.o.;
};

export const loadAnalyticsDataForSpecificDate = async (
    token: string,
    identifiers: string[],
    from: Date,
    to: Date,
): Promise<StoredAnalyticsData[]> => {
    const promises = identifiers.flatMap(async (identifier) => {
        return backendConnector.fetch('statistics/stored-analytics-specific [Get]', {
            queryParameters: {
                identifier,
                dateFrom: from.toISOString(),
                dateTo: to.toISOString(),
            },
        });
    });
    return (await Promise.all(promises)).flatMap((response) => response.result);
};

export const getSingleLineGraph = async (token: string, identifier: string, timeslot = Timeslot.twelveWeeks): Promise<ISingleLineGraph> => {
    const data = await loadAnalyticsData(token, [identifier], timeslot);
    const labels = data[0].result.map((singleLineGraphData) => singleLineGraphData.interval);
    const values = data[0].result.map((singleLineGraphData) => {
        if (!singleLineGraphData.values.length || !singleLineGraphData.values[0]?.value) return 0;
        return singleLineGraphData.values[0].value;
    });
    return {
        data: values,
        labels,
    };
};

export const getAbsoluteDataMulti = async (
    token: string,
    identifier: string,
    timeslot: Timeslot,
    average = false,
): Promise<IAbsoluteDataMulti> => {
    const loadedData = await loadAnalyticsData(token, [identifier], timeslot);

    const formattedData = loadedData[0].result.reduce(
        (acc, curr) => {
            curr.values.forEach((value) => {
                if (!acc[value.identifier]) acc[value.identifier] = [];
                acc[value.identifier].push(value.value);
            });
            return acc;
        },
        {} as Record<string, number[]>,
    );

    const data = Object.entries(formattedData).reduce(
        (allEntries, [key, value]) => {
            allEntries.labels.push(key);
            let newValue = 0;
            if (average) {
                newValue = value.reduce((acc, curr) => acc + curr, 0) / value.length;
            } else {
                newValue = value.reduce((acc, curr) => acc + curr, 0);
            }
            allEntries.data.push(newValue);
            return allEntries;
        },
        {
            labels: [] as string[],
            data: [] as number[],
        } as IAbsoluteDataMulti,
    );
    return data;
};

interface IMultiLineGraphData {
    label: string;
    data: number[];
}

export const getMultiLineGraphData = async (token: string, identifier: string, timeslot: Timeslot): Promise<IMultiLineGraph> => {
    const loadedData = await loadAnalyticsData(token, [identifier], timeslot);

    const isMonth = loadedData[0].result[0].interval.includes('/');

    const labels = isMonth
        ? loadedData[0].result.map((singleLineGraphData) => singleLineGraphData.interval)
        : loadedData[0].result.map((singleLineGraphData) => `KW ${singleLineGraphData.interval}`);

    const existingIdentifiers = loadedData[0].result
        .find((singleLineGraphData) => singleLineGraphData.values.length)
        ?.values?.map((value) => value.identifier);

    if (!existingIdentifiers?.length) {
        return {
            labels,
            data: [],
        };
    }

    const data: IMultiLineGraphData[] = existingIdentifiers.map((categoryIdentifier) => {
        const label = categoryIdentifier;
        const dataForCategory = labels.map(
            (week) =>
                loadedData[0].result
                    .find((singleLineGraphData) => `KW ${singleLineGraphData.interval}` === week)
                    ?.values.find((value) => value.identifier === categoryIdentifier)?.value || 0,
        );
        return {
            label,
            data: dataForCategory,
        };
    });

    return {
        labels,
        data,
    };
};
